.discount-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.discount-modal-content {
  background: white;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 1700px;
  height: 70%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  .MuiCheckbox-root .MuiSvgIcon-root {
    fill: black;
  }

  .close-button {
    position: absolute;
    top: 0px !important;
    right: 5px;
    background: none;
    border: none;
    font-size: 36px;
    cursor: pointer;

    @media (max-width: 900px) {
      color: white !important;
    }
  }
}

.form-container {
  width: 50%;

  padding: 40px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    text-align: center;

    form {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

@media (min-width: 768px) {
  .modal-content {
    width: 80%;
  }
}
.image-container,
.image-container-vallalati {
  background-image: url("../../assets/discount/otthonfelujitas.jpg");
  // make this element always fill the height of the parent
  height: 100%;
  width: 50%;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 900px) {
    background-image: url("../../assets/discount/otthon.jpg");
    width: 100%;
    height: 35%;
  }
}

.image-container-vallalati {
  background-image: url("../../assets/discount/otthonfelujitasvallalati.jpg");
  @media (max-width: 900px) {
    background-image: url("../../assets/discount/otthonvallalati.jpg");
  }
}

.discount-submit-button {
  background-color: black;
  color: white;
  margin-top: 24px;
  margin-left: auto;
  float: right;
  margin-right: calc(10%);
}

.checkbox-container {
  width: 85%;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;

  label {
    width: 100%;
  }
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  margin-bottom: 12px;

  .MuiTextField-root {
    width: 90%;
  }

  @media (max-width: 900px) {
    .MuiTextField-root {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.lakossagi-modal .input-container {
  @media (min-width: 800px) {
    .MuiTextField-root:last-child {
      width: 35%;
      margin-left: 12px;
    }
    .MuiTextField-root:nth-child(3) {
      width: calc(55% - 12px);
    }
  }
}

.button-gomb {
  @media (min-width: 900px) {
    float: left;
  }

  @media (max-width: 899.98px) {
    margin-right: auto;
    margin-left: auto;
  }
  background-color: black;
  color: white;
  margin-top: 24px;
}

.text-container {
  margin-bottom: 24px;
  width: 100%;

  h1 {
    margin-bottom: 12px;
  }
  p {
    padding-right: 48px;
  }
  @media (max-width: 900px) {
    p {
      padding-right: 0px;
    }

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.options-list {
  padding-top: 12px;
}
