.CustomCard {
  padding: 3rem;

  svg {
    vertical-align: top;
    padding-right: 8px;
  }

  .green-island-card {
    width: 100%;
    border: 2px solid white;
    padding: 2rem;
    height: 100%;
    border-radius: 10px;
    box-shadow: 10px 10px 0 #000;
  }

  .green-island-card__header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #000;
    padding-bottom: 1rem;

    img {
      height: auto;
      width: 100%;
    }
  }

  .green-island-card__icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    padding: 0.5rem;
  }

  .green-island-card__icon svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: #fff;
  }

  .green-island-card__alert {
    font-weight: 900;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .green-island-card__message,
  .green-island-card__title {
    justify-content: center;
    margin-top: 1rem;
    color: white;
    font-size: 0.9rem;
    line-height: 1.4;
    border-bottom: 2px dotted white;
    padding-bottom: 1rem;
    font-weight: 600;

    p {
      text-align: center;
      line-height: 1.8;
    }
  }

  .green-island-card__title {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }

  .green-island-card__actions {
    margin-top: 1rem;
  }

  .green-island-card__button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    border: 3px solid #000;
    background-color: #fff;
    color: #000;
    position: relative;
    transition: all 0.2s ease;
    box-shadow: 5px 5px 0 #000;
    overflow: hidden;
    text-decoration: none;
    margin-bottom: 1rem;
  }

  .green-island-card__button--read {
    background-color: #000;
    color: #fff;
  }

  .green-island-card__button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: all 0.6s;
  }

  .green-island-card__button:hover::before {
    left: 100%;
  }

  .green-island-card__button:hover {
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 #000;
  }

  .green-island-card__button--mark:hover {
    background-color: #296fbb;
    border-color: #296fbb;
    color: #fff;
    box-shadow: 7px 7px 0 #004280;
  }

  .green-island-card__button--read:hover {
    background-color: #ff0000;
    border-color: #ff0000;
    color: #fff;
    box-shadow: 7px 7px 0 #800000;
  }

  .green-island-card__button:active {
    transform: translate(5px, 5px);
    box-shadow: none;
  }
}
