.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 1700px;
  max-height: 80%;
  overflow-y: auto;
  padding-top: 40px;

  .close-button {
    position: absolute;
    top: -10px;
    right: 5px;
    background: none;
    border: none;
    font-size: 36px;
    cursor: pointer;
  }
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.video-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  width: 100%;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .modal-content {
    width: 80%;
  }
}
