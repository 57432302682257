@mixin tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}
