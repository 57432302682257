@use "../../sass/index" as *;

.solar {
  background-color: $color-black;
  color: $color-white;
  position: relative;
  transition: filter 0.5s ease;

  &.darken {
    filter: blur(5px);
    transition: filter 1.5s ease;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  &__hero {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15rem;
    align-items: center;
    position: relative;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: filter 0.5s ease;

    &.second {
      height: 90vh;
    }

    &__info {
      margin-top: 8rem;
      text-align: center;
      color: $color-black;

      h1 {
        font-size: 3rem;
        font-weight: 600;
      }

      p {
        font-weight: 400;
        font-size: 1.5rem;
      }
    }

    &__specs {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 2rem 4rem;
      text-align: center;
      padding: 3.5rem;
      color: $color-white;
      z-index: 11;
      background: none;

      @media (max-width: 601px) {
        &__div {
          justify-content: center;
        }
      }

      h2 {
        font-size: 2.5rem;
        font-weight: 600;
      }

      p {
        font-size: 1rem;
      }

      button {
        outline: 3px solid $color-white;
        transition: color 0.5s ease, background 0.5s ease;
      }

      button:hover {
        color: $color-black;
        background: $color-white;
      }

      @include tablet {
        padding: 3rem;
      }

      @include mobile {
        padding: 2rem;
      }
    }

    @include tablet {
      height: 80vh;
    }
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-black;

    &__cover {
      width: 100%;
      height: 75vh;
      padding-bottom: 2.5rem;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 8rem;
      text-align: center;
      position: relative;
    }

    &__specs {
      width: 100%;
      padding-block: 3rem;
      display: flex;
      justify-content: center;

      background-color: $color-black;
      color: $color-white;

      &__div {
        width: 70%;
        display: flex;
        justify-content: space-around;

        &__main {
          button {
            margin-top: 1rem;
            outline: 2px solid $color-black;
            background: $color-white;
            transition: color 0.5s ease, background 0.5s ease;
          }

          button:hover {
            background: $color-black;
            color: $color-white;
            outline: 2px solid $color-white;
          }

          p {
            opacity: 0.7;
          }
        }

        &__secondary {
          max-width: 50%;
          opacity: 0.8;
        }

        .button_div {
          display: flex;
          column-gap: 2rem;
          flex-wrap: wrap;
          justify-content: left;

          @include mobile {
            justify-content: center;
          }
        }

        @include mobile {
          width: 75%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 2rem;
          text-align: center;

          &__secondary {
            max-width: 100%;
          }
        }
      }
    }
  }

  &__about {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    background: $color-black;
    color: $color-white;

    &.reverse-wrap,
    &.reverse-wrap-desktop {
      flex-direction: row-reverse;
    }

    &__media {
      width: 55%;
      position: relative;
      margin-top: auto;
      margin-bottom: auto;

      video {
        min-width: 100%;
        max-height: 100vh;
        object-fit: cover;
      }
    }

    &__info {
      align-self: stretch;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 2rem;

      &__text {
        h3 {
          font-weight: 400;
          opacity: 0.7;

          @media (min-width: 825px) and (max-width: 1300px) {
            font-size: 1rem;
          }
        }

        h2 {
          font-weight: 500;
          font-size: 2rem;

          @media (min-width: 825px) and (max-width: 1300px) {
            font-size: 1.4rem;
          }

          @media (min-width: 1300px) and (max-width: 1920px) {
            font-size: 1.65rem;
          }
        }

        p {
          padding-top: 1.5rem;
          font-weight: 400;
          font-size: 0.85rem;
          text-align: justify;
          opacity: 0.8;
          margin-bottom: 24px;

          @media (max-width: 601.99px) {
            font-size: 0.9rem;
          }

          @media (min-width: 825px) and (max-width: 1300px) {
            font-size: 0.8rem;
          }

          @media (min-width: 1300px) {
            font-size: 1.1rem;
          }
        }
      }

      @media screen and (max-width: 1200px) {
        width: 45%;
      }
    }

    button {
      background: $color-white;
      outline: 2px solid $color-black;
      transition: color 0.5s ease, background 0.5s ease;
    }

    button:hover {
      background: $color-black;
      color: $color-white;
    }

    @media screen and (max-width: 825px) {
      flex-wrap: wrap;

      &__media {
        width: 100%;

        img {
          width: 100%;
        }
      }

      &__info {
        width: 100%;

        &__btn {
          margin-top: 2rem;
        }
      }
    }

    &.reverse-wrap {
      @media screen and (max-width: 824.99px) {
        flex-wrap: wrap-reverse;
      }
    }

    &.reverse-wrap-desktop {
      @media screen and (min-width: 825px) {
        flex-wrap: wrap-reverse;
      }
    }
  }

  &__specs {
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
      width: 100%;
      padding: 10rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        flex: 1;
      }

      &__specs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5rem;
        gap: 3rem;

        &__title {
          font-size: 1.25rem;
          h2 {
            font-weight: 600;
          }
          span {
            font-weight: 300;
          }
        }

        &__details {
          display: flex;
          flex-wrap: wrap;

          &__item {
            width: 40%;
            font-size: 0.9rem;
            padding-block: 0.75rem;
            margin-bottom: 3rem;
            position: relative;

            p:first-child {
              font-weight: 600;
            }
            p:last-child {
              font-weight: 300;
            }

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 10%;
              height: 1px;
              opacity: 0.5;
              background-color: $color-white;
            }
          }
        }
      }

      @include tablet {
        flex-direction: column;
        padding: 0;
      }
    }
  }

  &__footer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      font-size: 1.25rem;
      h2 {
        font-weight: 600;
      }
      p {
        font-size: 0.9rem;
        font-weight: 400;
      }

      &__btns {
        width: 100%;

        button {
          width: 75%;
          outline: 2px solid $color-white;
          transition: color 0.5s ease, background 0.5s ease;

          &:hover {
            background: $color-white;
            color: $color-black;
          }

          &:first-child {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

.solar__hero__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire area */
  z-index: 0; /* Ensure the video is behind other content */
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 64px;
}

.child {
  flex: 1 1 100%; /* Each child occupies 100% of the container width by default */
  min-width: 250px;
  max-width: 500px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: black !important;
  border-radius: 8px;
  padding: 24px 32px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 18px 50px -10px !important;
  height: 100%;
  color: white !important;
}

.img-container {
  width: 100%;
  height: 200px; /* Fixed height for the container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-img {
  max-height: 100%;
  width: auto;
  border-radius: 8px 8px 0 0;
}

.card p {
  flex: 1; /* Make the paragraph take up the remaining space */
  text-align: justify;
  opacity: 0.8;
}

.title {
  margin-top: 16px;
  margin-bottom: 8px;
}

@media screen and (min-width: 992px) {
  .child {
    flex: 1 1 calc(33.33% - 24px); /* Each child occupies 33.33% of the container width with 16px gap */
  }
}

.cardsContainer {
  text-align: center;
  width: 95%;
}

.videoParentContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15rem;
  align-items: center;
  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: filter 0.5s ease;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire area */
    z-index: 0; /* Ensure the video is behind other content */
  }
}

.fade-bottom::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.7) 85%,
    rgba(0, 0, 0, 1) 100%
  );
}

.fade-bottom-left::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.7) 85%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.7) 85%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.7) 85%,
      rgba(0, 0, 0, 1) 100%
    );
}

.fade-bottom-top-right::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.3) 85%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.5) 85%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.7) 85%,
      rgba(0, 0, 0, 1) 100%
    );
}

.fade-bottom-top::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.5) 85%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.7) 85%,
      rgba(0, 0, 0, 1) 100%
    );
}

.blog-link {
  color: #8edd65 !important;
}

.blog-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.full-imagee {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

@media (min-width: 768px) {
  .blog-video {
    max-height: 50vh !important;
  }
}

.blog-video {
  max-height: 100vh;
  margin: auto;
}

.blog-text {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  opacity: 1;
  color: white;
  font-size: 1rem;
  white-space: pre-wrap;
}

.blog-text-main-page {
  font-family: "Montserrat", sans-serif;
  opacity: 1;
  color: white;
  white-space: pre-wrap;
}

.blog-parent {
  @media (min-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 1199.98px) {
    width: 75%;
  }

  margin-left: auto;
  margin-right: auto;
}

.MuiAccordionSummary-content {
  padding-right: 12px;
}

.blog-second-parent {
  text-align: left !important;
}

.discount-modal-button {
  background: transparent;
  color: white;
  padding: 0;
  border: 0;
  outline: 0 !important;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;

  &:hover {
    color: white !important;
    background: transparent !important;
  }
}

.list-styling {
  list-style-type: circle;
  opacity: 0.8;

  li {
    list-style-type: circle;
  }
}

.blogok-pagination {
  .MuiPaginationItem-root.Mui-selected {
    color: black;
    background-color: white;

    &:hover {
      background-color: black;
      color: white;
      border: 1px solid white;
    }
  }
  .MuiPaginationItem-root {
    color: white;
  }

  &.MuiPagination-root {
    display: flex;
    justify-content: center;
  }
}

.kalkulator-modal .MuiBox-root {
  max-width: 800px;
  width: 100%;

  @media (max-width: 800px) {
    max-width: 100%;
  }
}

.kalkulator-modal .MuiInputLabel-outlined {
  color: black !important;
}

.kalkulator-modal .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}
