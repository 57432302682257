@use "./sass/index" as *;

@import url("https://fonts.googleapis.com/css2?family=Michroma&family=Montserrat:wght@300;400;500;600;700;800;900&family=Orbitron:wght@400;700;800&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background: $color-footer;
  box-shadow: inset 0 0 5px grey;
}
::-webkit-scrollbar-thumb {
  background: $color-btn-light;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: $color-black;
}

html {
  font-size: 100%;

  @include tablet {
    font-size: 80%;
  }

  @include mobile {
    font-size: 65%;
  }

  scroll-behavior: smooth;
}

body {
  font-family: $font-body;
  font-weight: 500;
  line-height: 1.5;

  color: $color-text;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style-type: none;
}

img,
video {
  display: block;
  max-width: 100%;
}

button,
input,
textarea,
select {
  font: inherit;
  outline: 0;
}

button {
  padding: 0.6rem 4.25rem;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  font: inherit;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-primary {
  background-color: $color-btn-dark;
  color: $color-white;
}

.btn-secondary {
  background-color: $color-btn-light;
  color: $color-black;
}

.btn-secondary-inverse {
  background-color: $color-black !important;
  color: $color-white !important;
  outline: 2px solid white !important;
}

.CookieConsent {
  div:first-child {
    flex: 1 1 300px !important;
  }
}

.CookieConsent {
  z-index: 1001 !important;
}

body.modal-open {
  overflow: hidden;
}
