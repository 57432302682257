.button-fix {
  color: black !important;

  &:hover {
    color: white !important;
  }
}

.otthonfelujitas-form label,
.otthonfelujitas-form .MuiFormLabel-root {
  overflow-wrap: break-word;
  white-space: normal;
}

.second-button {
  border: black 2px solid !important;
  color: black !important;
  background-color: white !important;

  &:hover {
    color: white !important;
    background-color: black !important;
  }
}

.modal-canvas {
  canvas {
    width: 100%;
    height: 100%;
  }
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  height: 100%;
}
