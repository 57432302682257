$color-text: #171a20;

$color-btn-dark: #171a20cc;
$color-btn-light: #f4f4f4a6;

$color-footer: #5c5e62;

$color-white: #fff;
$color-black: #000;

$font-body: "Montserrat", sans-serif;
$font-header: "Michroma", sans-serif;
$font-header-alternative: "Orbitron", sans-serif;
