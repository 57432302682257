.floating-chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

#sutiNemElfogadva {
  bottom: 60px;
  @media (max-width: 425px) {
    bottom: 100px;
  }
}

.circle {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
}

.chat-box {
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Updated transition property */
  opacity: 0; /* Hide chat box when not open */

  input,
  button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  button {
    background-color: black;
    color: #fff;
    cursor: pointer;
  }

  padding-top: 60px;
}

.chat-box.open {
  transform: translateY(0);
  opacity: 1;
  animation: slideIn 0.5s forwards;
}

.chat-box.close {
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;

  background: transparent !important;
  color: black !important;
  border: none !important;
  text-align: right;
  padding-right: 0px !important;
  font-size: 30px;
  margin-top: 0px;
  width: 18px;
  padding: 0 !important;
  padding-top: 6px !important;
  margin-right: 16px;
}

.close-button:hover {
  color: #8edd65 !important;
}

.floating-chat-logo {
  position: absolute;
  width: 45%;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  &:hover {
    cursor: pointer;
  }
}

.load-wrapp {
  float: left;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  background-color: transparent;
}

.load-wrapp p {
  padding: 0 0 20px;
}
.load-wrapp:last-child {
  margin-right: 0;
}

.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: white;
}

.load-2 .line:nth-last-child(1) {
  animation: loadingB 1.5s 1s infinite;
}
.load-2 .line:nth-last-child(2) {
  animation: loadingB 1.5s 0.5s infinite;
}
.load-2 .line:nth-last-child(3) {
  animation: loadingB 1.5s 0s infinite;
}

@keyframes loadingB {
  0% {
    width: 15px;
  }
  50% {
    width: 35px;
  }
  100% {
    width: 15px;
  }
}

.hover-cursor:hover {
  cursor: pointer;
}
